import React, { useContext, useEffect } from "react"
import onScan from "onscan.js"
import PosProductContext from "main/context/pos/PosProduct/PosProductContext"
import Search from "main/common/Search"
import { fetchDataWithSearchPaginated } from "offline_database/queries/DbOperations"
import debounceHelper from "main/util/DebounceHelper"

const BarcodeScanner = ({ searchProducts }: any) => {
  const {
    barcodeSearch,
    setBarcodeSearch,
    barcodeSearchRef,
    showError,
    setShowError
  } = useContext(PosProductContext) as any

  const debouncedSetBarcodeSearch = debounceHelper((searchTerm: string) => {
    handleSearch(searchTerm)
  }, 1000)

  useEffect(() => {
    const keyCodeMap: any = {
      189: "-",
      173: "-",
      190: ".",
      188: ",",
      191: "/",
      186: ":",
      222: "'",
      219: "[",
      221: "]",
      220: "\\",
      187: "=",
      192: "~",
      223: "`"
    }
    onScan.attachTo(document, {
      onScan: (scannedCode: any) => {
        barcodeSearchRef?.current?.focus()
        debouncedSetBarcodeSearch(String(scannedCode))
      },
      keyCodeMapper: function (event) {
        const keyCode = event.which || event.keyCode
        if (Object.keys(keyCodeMap).includes(String(keyCode))) {
          return keyCodeMap[keyCode]
        }
        return onScan.decodeKeyEvent(event)
      }
    })

    return () => {
      onScan.detachFrom(document)
    }
  }, [])

  const handleSearch = (searchTerm: string | number): void => {
    setShowError("")
    setBarcodeSearch((prev: any) => {
      const value = extractSyngentaValues(prev ? prev : "" + searchTerm)
      searchProducts(
        fetchDataWithSearchPaginated,
        "productList",
        "barcode",
        value,
        50
      )
      return value
    })
  }

  const handlePaste = (e: any): void => {
    const searchValue = e?.clipboardData?.getData("text")

    setTimeout(() => {
      handleSearch(searchValue)
    }, 200)
  }

  const handleChange = (searchTerm: string): void => {
    setShowError("")
    setBarcodeSearch(searchTerm)
    if (!searchTerm)
      searchProducts(
        fetchDataWithSearchPaginated,
        "productList",
        "barcode",
        searchTerm,
        50
      )
  }

  function extractSyngentaValues(value: string) {
    const regex = /01\/(\d+)\/.*?10\/([^/?]+)/
    const match = value.match(regex)
    return match ? `${match[1]}~${match[2]}` : value
  }

  return (
    <Search
      hideSearchIcon
      label="Barcode Search"
      placeholder="Barcode"
      searchVariant="outlined"
      searchValue={barcodeSearch}
      setSearchValue={handleChange}
      searchInputRef={barcodeSearchRef}
      error={showError}
      onEnter={handleSearch}
      onPaste={handlePaste}
    />
  )
}

export default BarcodeScanner
