export const allowOnlyNumbers = (value: string): string => {
  return value?.replace(/[^0-9]/g, "")
}
export const checkIsNumberOrDecimal = (value: string): string => {
  return value?.replace(/[^0-9.]/g, "").replace(/(\..*)\..*/g, "$1")
}

export const allowPositiveNumbers = (value: string) => {
  const regex = /^[1-9]\d*$/
  return regex.test(value) ? value : "1"
}

export const convert03to92 = (number: string): string => {
  if (number[0] === "0") {
    return number.replace("0", "+92")
  }
  return number
}

export const displayAmount = (price: number): string | number => {
  const customPrice = String(price)
  if (customPrice === "0") return "0"
  return customPrice.includes(".")
    ? parseFloat(customPrice)?.toFixed(2).toLocaleString()
    : customPrice
}
