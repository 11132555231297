import { useContext } from "react"
import { displayAmount } from "main/util/NumberHelper"
import RowItem from "main/pos/receipt/common/RowItem"
import RowThreeCols from "main/pos/receipt/common/RowThreeCols"
import {
  lotProductTotal,
  totalLotQuantity
} from "main/pos/product/product_lots/helpers/ProductLotsHelper"
import {
  calculateSubItemsTotal,
  createOptionsForOfflineData
} from "main/pos/helpers/ProductHelpers"
import PosContext from "main/context/pos/PosContext"
import { PosContextType } from "main/context/pos/PosContextTypes"
import ConnectionHooks from "main/context/connection/ConnectionHook"

const CustomerProductList = ({
  products,
  showDiscountedAmount
}: {
  products: any
  showDiscountedAmount: any
}) => {
  const productLotCommentCharacters = ")x"
  const posContext = useContext(PosContext) as PosContextType
  const { isInternetOn } = ConnectionHooks()

  const { voucherDiscount, voucherDetails } = posContext

  const getTotal = (product: any) => {
    if (product?.productLots?.length) {
      return displayAmount(
        lotProductTotal(product, "productLots", "quantityInside")
      )
    }

    return displayAmount(
      !isInternetOn
        ? Number(calculateSubItemsTotal(product))
          ? (Number(product.discountedPrice || product.price) +
              Number(calculateSubItemsTotal(product))) *
            product.quantity
          : Number(product.discountedPrice || product.price) * product.quantity
        : (Number(product.discountedPrice || product.price) +
            Number(calculateSubItemsTotal(product))) *
            product.quantity
    )
  }

  const getPrice = (product: any) => {
    if (product?.productLots?.length) {
      const totalQty = totalLotQuantity(
        product,
        "productLots",
        "quantityInside"
      )
      const total = lotProductTotal(product, "productLots", "quantityInside")
      return displayAmount(total / totalQty)
    }
    return displayAmount(
      !isInternetOn
        ? Number(calculateSubItemsTotal(product))
          ? Number(product.discountedPrice || product.price) +
            Number(calculateSubItemsTotal(product))
          : Number(product.discountedPrice || product.price)
        : Number(product.discountedPrice || product.price) +
            Number(calculateSubItemsTotal(product))
    )
  }

  return (
    <>
      {products?.map((existingProduct: any) => {
        const isProductLotComment = existingProduct?.comment?.includes(
          productLotCommentCharacters
        )
        const product: any = createOptionsForOfflineData(existingProduct) || {}

        const categoryVoucherDiscount = Number(
          showDiscountedAmount(product, voucherDetails, voucherDiscount)
        )

        return (
          <>
            <RowItem
              labelBold
              valueBold
              fontClass="fontNormal"
              fullIndent
              label={`${product?.quantity} x ${product?.name}`}
              extraLabel={product?.selectedOptions}
            />
            {product?.comment && !isProductLotComment ? (
              <RowItem
                labelIndent
                fullIndent
                fontClass="fontSmall"
                label={`Instructions: ${product?.comment}`}
              />
            ) : (
              <></>
            )}
            <RowThreeCols
              fontClass="fontNormal"
              label=""
              midValue={getPrice(product)}
              discountedAmount={categoryVoucherDiscount}
              midValueBold
              value={displayAmount(
                Number(getTotal(product)) - categoryVoucherDiscount
              )}
              valueBold
            />
            <hr />
          </>
        )
      })}
    </>
  )
}

export default CustomerProductList
