import TapShopLogo from "assets/images/logos/whiteLabelLogos/tap_shop.png"
import NayaSaveraLogo from "assets/images/logos/whiteLabelLogos/naya_savera.png"
import TraxShopLogo from "assets/images/logos/whiteLabelLogos/trax_shop.png"
import ChikooLogo from "assets/images/logos/chikoo_orange.png"

import GulbergGreenfavicon from "assets/images/logos/whiteLabelLogos/islamabad-foodies-favicon.jpg"
import GulbergGreenLogo from "assets/images/logos/whiteLabelLogos/islamabad-foodies-logo.jpg"
import GulbergGreenInvertedLogo from "assets/images/logos/whiteLabelLogos/islamabad-foodies-logo-inverted.jpg"

export type WhiteLabelPartnerType = {
  [key: string]: {
    title: string
    tld: string
    slug: string | null
    favIcon: string
    logo: string
    website: string
    theme: WhiteLabelPartnerTheme | null
    invertedLogo?: string
  }
}
type WhiteLabelPartnerTheme = {
  primary: string
  secondary: string
  textDark: string
  textLight: string
  tint?: string
}
const WhiteLabelsPartners: WhiteLabelPartnerType = {
  chikoo: {
    title: "Chikoo",
    tld: "chikoo.app",
    slug: null,
    favIcon: "/favIcons/favicon-32x32.png",
    logo: ChikooLogo,
    website: "https://getchikoo.com",
    theme: {
      primary: "#FEB05A",
      secondary: "#5D9870",
      textDark: "#343434",
      textLight: "#6E757C",
      tint: "#FEF7EE"
    }
  },
  nayasavera: {
    title: "Naya Savera",
    tld: "nayasavera.app",
    slug: "nayasavera",
    favIcon: "/whiteLabelLogo/nayasavera.png",
    logo: NayaSaveraLogo,
    website: "#",
    theme: {
      primary: "#a44494",
      secondary: "#B02FBD",
      textDark: "#343434",
      textLight: "#6E757C",
      tint: "#eddaea"
    }
  },
  tapshop: {
    title: "Tap Shop",
    tld: "tapshop.app",
    slug: "tapshop",
    favIcon: "/whiteLabelLogo/tapshop.png",
    logo: TapShopLogo,
    website: "#",
    theme: {
      primary: "#85B42B",
      secondary: "#388697",
      textDark: "#343434",
      textLight: "#6E757C"
    }
  },
  traxshop: {
    title: "Trax Shop",
    tld: "traxshop.app",
    slug: "traxshop",
    logo: TraxShopLogo,
    favIcon: "/whiteLabelLogo/traxshop.png",
    website: "#",
    theme: {
      primary: "#76BB7F",
      secondary: "#5D9870",
      textDark: "#343434",
      textLight: "#6E757C"
    }
  },
  islamabadfoodies: {
    title: "Islamabad Foodies",
    tld: "islamabadfoodies.com",
    slug: "islamabadfoodies",
    favIcon: GulbergGreenfavicon,
    logo: GulbergGreenLogo,
    invertedLogo: GulbergGreenInvertedLogo,
    website: "#",
    theme: {
      primary: "#009846",
      secondary: "#b0cb1f",
      textDark: "#2f2e2d",
      textLight: "#73837a",
      tint: "#d1f0df"
    }
  }
}

export default WhiteLabelsPartners
