import React, {
  ReactInstance,
  useContext,
  useEffect,
  useRef,
  useState
} from "react"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { gql, useLazyQuery, useMutation } from "graphql/apollo"
import UserContext from "main/context/user/UserContext"
import { UserContextType } from "main/context/user/UserContextTypes"
import { getCurrentDateTime } from "main/util/DateTimeHelper"
import { useReactToPrint } from "react-to-print"
import {
  addToStorage,
  getFromStorage,
  keys,
  removeFromStorage
} from "main/util/LocalStorageHelper"
import pulse from "graphql/pulse"
import { CircularProgress } from "@mui/material"
import Validator from "main/util/Validator"
import { allowOnlyNumbers, displayAmount } from "main/util/NumberHelper"
import PosContext from "main/context/pos/PosContext"
import { PosContextType } from "main/context/pos/PosContextTypes"
import ShiftUserReport from "../receipt/ShiftUserReport"

const ShiftForm = ({
  isShiftStarted,
  setIsShiftStarted,
  dialog,
  setDialog
}: {
  isShiftStarted: boolean
  setIsShiftStarted: any
  dialog: boolean
  setDialog: any
}) => {
  const { setShiftBvid } = useContext(PosContext) as PosContextType
  const { user, getName } = useContext(UserContext) as UserContextType
  const [shiftStartCash, setShiftStartCash] = useState("")
  const [shiftEndCash, setShiftEndCash] = useState("")
  const [totalExpense, setTotalExpense] = useState("")
  const [totalSales, setTotalSales] = useState(0)
  const [refundAmount, setRefundAmount] = useState(0)
  const [shiftNote, setShiftNote] = useState("")
  const [unbalanceReason, setUnbalanceReason] = useState("")
  const [balanceAmount, setBalanceAmount] = useState(0)
  const [cashSales, setCashSales] = useState(0)
  const [startCashError, setStartCashError] = useState("")
  const [endCashError, setEndCashError] = useState("")
  const [balanceError, setBalanceError] = useState("")
  const [loading, setLoading] = useState(false)
  const [shiftReportData, setShiftReportData] = useState({})

  const shiftReportRef = useRef<ReactInstance>(null)

  const handleShiftReceipt = useReactToPrint({
    content: () => shiftReportRef.current
  })

  const [createShift] = useMutation(CREATE_SHIFT, {
    client: pulse,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true
  })

  const [updateShift] = useMutation(UPDATE_SHIFT, {
    client: pulse,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true
  })

  const [shiftOrderReport] = useLazyQuery(SHIFT_ORDER_REPORT, {
    client: pulse,
    fetchPolicy: "network-only"
  })

  const getShiftFromStorage = () => {
    const shiftInfo = getFromStorage(keys.SHIFT)

    if (shiftInfo) {
      setShiftStartCash(shiftInfo.shiftStartCash)
      setTotalSales(shiftInfo.totalSales || 0)
      setRefundAmount(shiftInfo.cashRefunds || 0)
      setCashSales(shiftInfo.cashSales || 0)
    }
  }

  const calculateBalance = () => {
    const amount = +shiftStartCash + +cashSales - +totalExpense - refundAmount

    let unbalance = 0
    if (shiftEndCash) {
      unbalance = amount - +shiftEndCash
    }

    setBalanceAmount(unbalance)
  }

  const storeShift = async () => {
    setStartCashError("")
    const { userMaster, userMerchant } = user?.user ?? {}
    if (shiftStartCash === "") {
      setStartCashError("Cash in hand required")
      return
    }
    if (!Validator.isNumberValid(shiftStartCash)) {
      setStartCashError("Cash in hand should be a number")
      return
    }
    const createResponse = await createShift({
      variables: {
        values: {
          shiftStartCash: +shiftStartCash,
          shiftStartTime: getCurrentDateTime(),
          ...(userMaster?.bvid && { masterBvid: userMaster?.bvid }),
          ...(userMerchant?.bvid && { merchantBvid: userMerchant?.bvid })
        }
      }
    })

    if (createResponse?.data?.createShift?.bvid) {
      const newShift = {
        shiftBvid: createResponse?.data?.createShift?.bvid,
        shiftStartCash: +shiftStartCash,
        shiftStartTime: getCurrentDateTime()
      }
      addToStorage(keys.SHIFT, newShift)
      setDialog(false)
      setIsShiftStarted(true)
      setShiftBvid(createResponse?.data?.createShift?.bvid)
      setShiftReportData({})
    }
  }

  const combineAndCreateShift = async (isReportPrint = false) => {
    const isFormValid = createShiftValidation()
    if (isFormValid) {
      setLoading(true)
      const shiftInfo = getFromStorage(keys.SHIFT)
      const { shiftBvid, ...restShiftData } = shiftInfo

      const shift = {
        ...restShiftData,
        shiftEndTime: getCurrentDateTime(),
        cashExpenses: +totalExpense,
        shiftEndCash: +shiftEndCash,
        balanceAmount: balanceAmount,
        unreconciledAmountReason: unbalanceReason,
        shiftNote,
        totalSales,
        cashSales,
        cashRefunds: refundAmount
      }

      const updateResponse = await updateShift({
        variables: {
          values: shift,
          shiftBvid
        }
      })

      if (updateResponse?.data?.updateShift) {
        removeFromStorage(keys.SHIFT)
        setIsShiftStarted(false)
        setShiftStartCash("")
        setShiftBvid("")
      }

      if (isReportPrint) {
        const shiftReportResponse = await shiftOrderReport({
          variables: {
            shiftBvid
          }
        })

        if (shiftReportResponse?.data?.shiftOrderReport) {
          setShiftReportData(shiftReportResponse.data.shiftOrderReport)
        }
      }
      setDialog(false)
      setLoading(false)
    }
  }

  const createShiftValidation = () => {
    setEndCashError("")
    setBalanceError("")
    let isValid = true
    if (shiftEndCash === "") {
      isValid = false
      setEndCashError("Cash in hand is required")
    }
    if (shiftEndCash && !Validator.isNumberValid(shiftEndCash)) {
      isValid = false
      setEndCashError("Cash in hand should be a number")
    }
    if (balanceAmount && !unbalanceReason) {
      isValid = false
      setBalanceError("Add reason for unbalance amount")
    }
    return isValid
  }

  const handleShiftStartChange = (e: any) => {
    const value = allowOnlyNumbers(e.target.value)
    setShiftStartCash(value)
  }

  const handleTotalExpenseChange = (e: any) => {
    const value = allowOnlyNumbers(e.target.value)
    setTotalExpense(value)
  }

  const handleShiftEndCashChange = (e: any) => {
    const value = allowOnlyNumbers(e.target.value)
    setShiftEndCash(value)
  }

  useEffect(() => {
    getShiftFromStorage()
  }, [dialog])

  useEffect(() => {
    if (shiftStartCash) calculateBalance()
  }, [shiftStartCash, shiftEndCash, totalExpense, refundAmount])

  useEffect(() => {
    if (Object.keys(shiftReportData).length > 0) {
      handleShiftReceipt()
    }
  }, [shiftReportData])

  return (
    <Grid container rowSpacing={2}>
      {!isShiftStarted ? (
        <>
          <Grid item xs={12}>
            <Typography>Hi, {getName()}</Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Cash In Hand"
              size="small"
              error={!!startCashError}
              helperText={startCashError}
              value={shiftStartCash}
              onChange={handleShiftStartChange}
            />
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12}>
            <Typography>Welcome Back, {getName()}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>Starting Cash In Hand: {shiftStartCash}</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Total Expense"
              size="small"
              value={totalExpense}
              onChange={handleTotalExpenseChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Cash In Hand at shift end"
              size="small"
              error={!!endCashError}
              helperText={endCashError}
              value={shiftEndCash}
              onChange={handleShiftEndCashChange}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography>Total Sales: {displayAmount(totalSales)}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              Total Cash Sales: {displayAmount(cashSales)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              Refunded amount: {displayAmount(refundAmount)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              Amount to be reconciled: {displayAmount(balanceAmount)}
            </Typography>
          </Grid>
          {balanceAmount ? (
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                label="Reason for unbalance amount"
                variant="outlined"
                minRows={3}
                maxRows={4}
                error={!!balanceError}
                helperText={balanceError}
                value={unbalanceReason}
                onChange={(e) => setUnbalanceReason(e.target.value)}
              />
            </Grid>
          ) : (
            <></>
          )}

          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              label="Shift notes"
              variant="outlined"
              minRows={3}
              maxRows={4}
              value={shiftNote}
              onChange={(e) => setShiftNote(e.target.value)}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        {loading ? (
          <CircularProgress />
        ) : (
          <Grid container gap={2}>
            <Button
              variant="contained"
              size="small"
              onClick={() =>
                !isShiftStarted ? storeShift() : combineAndCreateShift(false)
              }
            >
              {isShiftStarted ? "End Shift" : "Start Shift"}
            </Button>
            {isShiftStarted ? (
              <Button
                variant="contained"
                size="small"
                onClick={() => combineAndCreateShift(true)}
              >
                End Shift with Report
              </Button>
            ) : (
              <></>
            )}
          </Grid>
        )}
      </Grid>
      <Grid container style={{ display: "none" }}>
        <ShiftUserReport
          ref={shiftReportRef}
          data={{ ...shiftReportData, userName: getName() }}
        />
      </Grid>
    </Grid>
  )
}
export default ShiftForm

const CREATE_SHIFT = gql`
  mutation CreateShift($values: ShiftInput) {
    createShift(values: $values) {
      bvid
    }
  }
`

const UPDATE_SHIFT = gql`
  mutation updateShift($shiftBvid: ID!, $values: ShiftUpdateInput) {
    updateShift(values: $values, shiftBvid: $shiftBvid)
  }
`

const SHIFT_ORDER_REPORT = gql`
  query ShiftOrderReport($shiftBvid: String!) {
    shiftOrderReport(shiftBvid: $shiftBvid) {
      totalSalesSummary {
        totalOrders
        totalSalesValue
        discountValue
        fbrCharges
        taxAmount
        deliveryCharges
        loyaltyRedemption
        tip
      }
      cashSaleSummary {
        totalOrders
        totalSalesValue
        discountValue
        fbrCharges
        taxAmount
        deliveryCharges
        loyaltyRedemption
        tip
      }
      creditSaleSummary {
        totalOrders
        totalSalesValue
        discountValue
        fbrCharges
        taxAmount
        deliveryCharges
        loyaltyRedemption
        tip
      }
      cardSaleSummary {
        totalOrders
        totalSalesValue
        discountValue
        fbrCharges
        taxAmount
        deliveryCharges
        loyaltyRedemption
        tip
      }
      alreadyPaidSaleSummary {
        totalOrders
        totalSalesValue
        discountValue
        fbrCharges
        taxAmount
        deliveryCharges
        loyaltyRedemption
        tip
      }
      onlinePaymentSaleSummary {
        totalOrders
        totalSalesValue
        discountValue
        fbrCharges
        taxAmount
        deliveryCharges
        loyaltyRedemption
        tip
      }
      returnSummary {
        totalOrders
        totalRefundValue
        cashReturnValue
        digialReturnValue
      }
      shiftEndTime
      shiftStartTime
    }
  }
`
