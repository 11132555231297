import React, { useState, useRef, useContext } from "react"
import { isEqual, omit } from "lodash-es"
import { PosProductProvider } from "main/context/pos/PosProduct/PosProductContext"
import PosContext from "main/context/pos/PosContext"
import { PosContextType } from "main/context/pos/PosContextTypes"
import PosCustomerContext from "main/context/pos/PosCustomer/PosCustomerContext"
import { PosCustomerType } from "main/context/pos/PosCustomer/PosCustomerTypes"
import { scrollTopOnValidation } from "main/pos/helpers/PosHelpers"
import {
  useDefaultDeliveryFlag,
  useExtractDeliveryCharges
} from "main/pos/hooks/PosHooks"
import {
  calculateSubItemsTotal,
  convertSubVariantDetails,
  getProductPrice,
  isQuantityOverLotSize,
  generateCommentQuantityByEachBarCode,
  batchProductTotal,
  isQuantityOverBatchSize
} from "main/pos/helpers/ProductHelpers"
import ConfigContext from "main/context/config/ConfigContext"
import { ConfigContextTypes } from "main/context/config/ConfigContextTypes"
import { isDigital } from "main/util/ProductHelper"
import { productType } from "constant/ProductConstant"
import {
  increaseLotQuantity,
  lotProductTotal
} from "main/pos/product/product_lots/helpers/ProductLotsHelper"
import { checkPastDateTime } from "main/util/DateTimeHelper"
import { fetchDataWithSearch } from "offline_database/queries/DbOperations"
import { displayAmount } from "main/util/NumberHelper"

const PosProductContainer = ({ children }: { children: React.ReactNode }) => {
  const posContext = useContext(PosContext) as PosContextType
  const posCustomerContext = useContext(PosCustomerContext) as PosCustomerType
  const { showCustomerName, setShowSummary, email, setEmailError } =
    posCustomerContext
  const { isRestaurantMode, isProductLotOn } = useContext(
    ConfigContext
  ) as ConfigContextTypes
  const defaultDeliveryFlag = useDefaultDeliveryFlag()

  const productSearchRef = useRef(null)
  const barcodeSearchRef = useRef(null)
  const [productSearch, setProductSearch] = useState("")
  const [openOptionDialog, setOpenOptionDialog] = useState(false)
  const [selectedProductsList, setSelectedProductsList] = useState<
    {
      xid: string
      quantity: number
      categoryId: string
      price: number
      barcode: number
      comment: string
      batches?: any
      batchedTotal?: number
      lotTotal?: number
      selectedLot?: Record<string, unknown>
      productLots?: Record<string, unknown>
      isPrintable?: boolean
    }[]
  >([])
  const [selectedProduct, setSelectedProduct] = useState({})
  const [selectedOptions, setSelectedOptions] = useState<any>([])
  const [selectedVariants, setSelectedVariants] = useState<any>([])
  const [isProductUpdate, setIsProductUpdate] = useState(false)
  const [productEditIndex, setProductEditIndex] = useState("")
  const [isProductFetched, setIsProductFetched] = useState(false)
  const [showError, setShowError] = useState("")
  const [openLotsDialog, setOpenLotsDialog] = useState(false)
  const [isInclusiveTax, setInclusiveTax] = useState(false)
  const [barcodeSearch, setBarcodeSearch] = useState("")

  const merchantProfile = async () =>
    await fetchDataWithSearch("merchantProfile")

  merchantProfile()?.then((data) =>
    setInclusiveTax(data[0].config.isInclusiveTax)
  )

  const checkEmailOnDigitalProduct = (product: any) => {
    return isDigital(product.productType) && !email && showCustomerName
  }

  const handleProduct = (product: any, isExtraItem: boolean) => {
    if (isExtraItem || isQuantityInLimit(product)) {
      if (checkEmailOnDigitalProduct(product)) {
        setShowSummary(false)
        scrollTopOnValidation(false)
        setEmailError(true)
      }
      if (product?.selectedLot?.length) {
        if (
          !isRestaurantMode() &&
          product.isBarCodeScan &&
          isQuantityOverLotSize(product, barcodeSearch, selectedProductsList)
        ) {
          setShowError("Scanned item limit exceeded")
          return
        } else if (
          !isRestaurantMode() &&
          product.isBarCodeScan &&
          checkPastDateTime(product?.selectedLot[0]?.expiry)
        ) {
          setShowError("Lot expired")
          return
        }
      }
      if (
        !isRestaurantMode() &&
        product.isBarCodeScan &&
        isQuantityOverBatchSize(product, barcodeSearch, selectedProductsList)
      ) {
        setShowError("Scanned item limit exceeded")
        return
      }
      if (selectedProductsList.length) {
        compareProductsForQuantity(product)
      } else {
        addCommentInProduct(product)
        if (product?.batches) {
          product.batchedTotal = batchProductTotal(product)
        }
        if (product?.productLots?.length) {
          product.lotTotal = lotProductTotal(product)
        }
        setSelectedProductsList((prev: any) => [product, ...prev])
      }
    }
  }

  const getProductLimit = (product: any) => {
    const { originalQuantity, maxOrderQuantity } = product

    return originalQuantity && maxOrderQuantity
      ? originalQuantity < maxOrderQuantity
        ? originalQuantity
        : maxOrderQuantity
      : maxOrderQuantity || originalQuantity
  }

  const isQuantityInLimit = (product: any, greaterEven = false) => {
    const { productLots } = product

    const productLimit = getProductLimit(product)

    if (productLimit !== null && !productLots?.length) {
      const totalQuantity = selectedProductsList.reduce(
        (previousProduct: any, filteredProduct: any) => {
          if (filteredProduct.name === product.name)
            return (previousProduct += parseInt(filteredProduct.quantity))
          return (previousProduct += 0)
        },
        0
      )
      if (greaterEven) return totalQuantity <= productLimit
      else return totalQuantity < productLimit
    }
    return true
  }

  const increaseQuantitySameProduct = (item: any, product: any, index: any) => {
    const extractTheseItems = [
      "quantity",
      "comment",
      "isBarCodeScan",
      "quantityError",
      "batchedTotal",
      "selectedLot",
      "lotTotal",
      "productLots",
      "isPrintable",
      "oldQuantity",
      "selectedOptions"
    ]
    const extractItem: any = omit(item, extractTheseItems)
    const extractProduct: any = omit(product, extractTheseItems)

    if (isEqual(extractItem, extractProduct)) {
      if (!isDigital(extractProduct.productType)) {
        addCommentInProduct(product, index)
        selectedProductsList[index].quantity += product?.cartonSize || 1
        delete selectedProductsList[index]?.isPrintable
        if (selectedProductsList[index]?.batches) {
          selectedProductsList[index].batchedTotal = batchProductTotal(
            selectedProductsList[index]
          )
        }
        if (selectedProductsList[index]?.productLots?.length) {
          const selectedLot = increaseLotQuantity(
            selectedProductsList[index],
            product
          )
          selectedProductsList[index].lotTotal = lotProductTotal(
            selectedProductsList[index]
          )
          selectedProductsList[index].selectedLot = selectedLot
        }
      }
      setSelectedProductsList([...selectedProductsList])
      return true
    }
    return false
  }

  const addCommentInProduct = (
    product: any,
    currentProductIndex?: number
  ): void => {
    const addCommentCondition = isRestaurantMode()
    if (addCommentCondition) return

    const searchTermBarCode =
      barcodeSearch && !isNaN(parseInt(barcodeSearch))
        ? barcodeSearch
        : `${product?.barcode}~${
            product?.selectedLot?.length ? product?.selectedLot[0]?.lotCode : ""
          }`

    if (!currentProductIndex && currentProductIndex !== 0) {
      product.comment = `(${searchTermBarCode})x${1}`
      return
    }
    const currentProduct = selectedProductsList[currentProductIndex]

    const combinedComments = generateCommentQuantityByEachBarCode(
      currentProduct,
      searchTermBarCode
    )
    selectedProductsList[currentProductIndex].comment = combinedComments
    setSelectedProductsList([...selectedProductsList])
  }

  const compareProductsForQuantity = (product: any) => {
    const productIndex = selectedProductsList
      .map((item: any, index: number) => {
        return increaseQuantitySameProduct(item, product, index)
      })
      .findIndex((item: any) => item === true)
    if (productIndex < 0) {
      addCommentInProduct(product)
      if (product?.batches) {
        product.batchedTotal = batchProductTotal(product)
      }
      if (product?.selectedLot) {
        product.lotTotal = lotProductTotal(product)
      }
      setSelectedProductsList((prev: any) => [product, ...prev])
    }
  }

  const getProductsSubTotal = (priceKey = "price") => {
    let categoryDiscount = 0
    const productSubtotal = selectedProductsList.reduce(
      (prevValue: any, product: any) => {
        let currentProductPrice = 0
        if (product?.batches) {
          currentProductPrice =
            product?.batchedTotal +
            calculateSubItemsTotal(product) * product.quantity
        }
        if (product?.productLots?.length) {
          currentProductPrice = product?.lotTotal
        } else if (isRestaurantMode()) {
          currentProductPrice =
            getProductPrice(product, priceKey) * product.quantity +
            calculateSubItemsTotal(product) * product.quantity
        } else {
          currentProductPrice =
            convertSubVariantDetails(product, product.subItems).price *
            product.quantity
        }
        const productPrice = prevValue + currentProductPrice

        if (!product?.extraItem) {
          categoryDiscount += currentProductPrice * (product.flatDiscount / 100)
        }
        return productPrice
      },
      0
    )

    posContext.setCategoryDiscount(categoryDiscount)
    return productSubtotal
  }

  const getProductsSubTotalArray = (priceKey = "price") => {
    const products = selectedProductsList.map((product: any) => {
      if (product.extraItem) {
        return (
          product?.price * product.quantity +
          calculateSubItemsTotal(product) * product.quantity
        )
      } else if (isRestaurantMode()) {
        return (
          product?.[priceKey] * product.quantity +
          calculateSubItemsTotal(product, true) * product.quantity
        )
      } else {
        return (
          convertSubVariantDetails(product, product.subItems).originalPrice *
          product.quantity
        )
      }
    })
    return products
  }

  const handleExtraItems = (extraItems: object[]) => {
    extraItems.forEach((item) => handleProduct(item, true))
  }

  const calculateProductsTotal = () => {
    const deliveryCharges = defaultDeliveryFlag ? 0 : posContext.deliveryCharges
    let total = getProductsSubTotal() - posContext.discount
    const tax = !isProductLotOn ? posContext.tax : 0
    total = total + tax
    total = total + deliveryCharges
    total = total + posContext.fbrCharges
    total = total + parseInt(posContext.tip || "0")
    if (total < 0) return 0
    return displayAmount(total)
  }

  const handleProductQuantity = (value: any, id: any) => {
    const selectedProducts = [...selectedProductsList]
    selectedProducts[id].quantity = parseInt(value || 0)

    if (selectedProducts[id]?.batches) {
      selectedProducts[id].batchedTotal = batchProductTotal(
        selectedProducts[id]
      )
    }
    if (selectedProducts[id]?.selectedLot) {
      selectedProducts[id].lotTotal = lotProductTotal(selectedProducts[id])
    }
    setSelectedProductsList(selectedProducts)
  }

  const productDelete = (index: any) => {
    setSelectedProductsList((previousProduct: any) => {
      previousProduct.splice(index, 1)
      return [...previousProduct]
    })
  }

  const optionDialogClose = () => {
    setOpenOptionDialog(false)
    setTimeout(() => {
      setProductSearch("")
      setBarcodeSearch("")
      setSelectedVariants([])
      setSelectedOptions([])
      setSelectedProduct({})
      setIsProductUpdate(false)
    }, 200)
  }

  const checkAndRemoveOptions = (optionDetails: any) => {
    return selectedOptions.find((selectedOption: any, index: any) => {
      const checkSubItems =
        optionDetails?.bvid === selectedOption?.bvid ||
        (optionDetails?.parent?.bvid === selectedOption?.parent?.bvid &&
          !selectedOption?.parent?.isMultiSelect)

      if (checkSubItems) {
        const tempOptions = [...selectedOptions]
        if (selectedOption.subOptions) {
          selectedOption.subOptions.forEach((subOption: any) => {
            if (subOption.isActive)
              subOption.subOptionValues.forEach((subOptionValue: any) => {
                tempOptions.forEach((tempOption: any, subIndex: any) => {
                  if (tempOption.bvid === subOptionValue.bvid) {
                    tempOptions.splice(subIndex, 1)
                  }
                })
              })
          })
          tempOptions.splice(index, 1)
        } else {
          tempOptions.splice(index, 1)
        }

        setSelectedOptions([...tempOptions])
      }
      return checkSubItems
    })
  }

  const checkAndRemoveVariants = (optionDetails: any) => {
    return selectedVariants.find((selectedOption: any, index: any) => {
      const checkVariants =
        optionDetails?.parent?.title === selectedOption?.parent?.title
      if (checkVariants) {
        const tempVariants = [...selectedVariants]
        tempVariants.splice(index, 1)
        setSelectedVariants([...tempVariants])
      }
      return checkVariants
    })
  }

  const isDigitalProductInCart = () => {
    const digitalProduct = selectedProductsList.find(
      (product: any) => product?.productType === productType.digital
    )
    return Boolean(digitalProduct)
  }

  const resetPosProductValues = () => {
    setSelectedProductsList([])
  }

  const applyPosProductValues = (productValues: any) => {
    setSelectedProductsList(productValues.products || [])
  }

  useExtractDeliveryCharges(getProductsSubTotal())
  return (
    <PosProductProvider
      value={{
        productSearchRef,
        productSearch,
        setProductSearch,
        openOptionDialog,
        setOpenOptionDialog,
        showError,
        setShowError,
        selectedProduct,
        setSelectedProduct,
        isDigitalProductInCart,
        selectedProductsList,
        setSelectedProductsList,
        selectedOptions,
        setSelectedOptions,
        selectedVariants,
        setSelectedVariants,
        isProductUpdate,
        setIsProductUpdate,
        productEditIndex,
        setProductEditIndex,
        handleProduct,
        getProductsSubTotal,
        calculateProductsTotal,
        getProductsSubTotalArray,
        handleExtraItems,
        handleProductQuantity,
        productDelete,
        isQuantityInLimit,
        optionDialogClose,
        checkAndRemoveOptions,
        checkAndRemoveVariants,
        resetPosProductValues,
        applyPosProductValues,
        isProductFetched,
        setIsProductFetched,
        openLotsDialog,
        setOpenLotsDialog,
        isInclusiveTax,
        getProductLimit,
        barcodeSearch,
        setBarcodeSearch,
        barcodeSearchRef
      }}
    >
      {children}
    </PosProductProvider>
  )
}

export default PosProductContainer
