import React, { SyntheticEvent, useEffect, useState } from "react"
import { limitText } from "main/util/TextFormatHelper"
import { getProductPrice } from "main/util/ProductHelper"
import { displayAmount } from "main/util/NumberHelper"
import { Button, Grid, Typography } from "@mui/material"
import QuantityBar from "main/common/option_button/QuantityBar"
import {
  ItemTypes,
  OptionValueType
} from "main/common/option_button/OptionButtonType"
import { SingleButtonTypes } from "main/common/option_button/SingleButtonTypes"

const styles = () => ({
  button: {
    width: 180,
    height: 72,
    fontSize: 14,
    fontWeight: 700,
    marginRight: 2,
    marginBottom: 2,
    "& .MuiButton-label": {
      flexDirection: "column"
    }
  }
})

const SingleButton = ({
  mainValues,
  optionValue,
  handleOptionButton,
  isButtonActive,
  optionButtonClass,
  priceKey,
  discountedPriceKey,
  getOptionQtySum,
  setMessage,
  setSnackBarState,
  multipleOptions,
  disabled
}: SingleButtonTypes) => {
  const classes = styles()
  const [quantity, setQuantity] = useState<number>()
  const [price, setPrice] = useState<string | number>("")

  useEffect(() => {
    setPrice(displayAmount(getProductPrice(optionValue, priceKey)))
  }, [])

  useEffect(() => {
    const qty = optionValue?.qty ?? 1
    setQuantity(qty)
  }, [optionValue?.qty])

  function validateMaxAllowedQty(mainOptionValue: OptionValueType) {
    const qty = mainOptionValue?.qty ?? 1
    if (mainValues?.quantity) {
      const isQtyExceed = mainOptionValue?.maxAllowed
        ? qty >= mainOptionValue.maxAllowed
        : qty >= mainValues?.quantity
      return isQtyExceed
    }
  }

  function setSnackBar(msg: string, e: SyntheticEvent<Element, Event>) {
    setMessage?.(msg)
    setSnackBarState?.(true)
    e.stopPropagation()
  }
  const handleQuantity = (
    e: SyntheticEvent<Element, Event>,
    increment: boolean
  ) => {
    const selected = multipleOptions.find(
      (mainoption: ItemTypes) => mainoption.bvid === optionValue?.bvid
    )

    if (mainValues?.quantity && mainValues.title && increment) {
      if (optionValue && validateMaxAllowedQty(optionValue)) {
        setSnackBar(
          `${optionValue?.title} max  allowed is ${
            optionValue?.maxAllowed ?? mainValues.quantity
          }`,
          e
        )
        return
      } else if (selected && getOptionQtySum() >= mainValues.quantity) {
        setSnackBar(
          `You can select only ${mainValues.quantity} option value for ${mainValues.title}`,
          e
        )
        return
      }
    }
    const qty = optionValue?.qty ?? 1
    const newQuantity = increment ? qty + 1 : qty > 1 ? qty - 1 : qty
    if (optionValue) {
      optionValue.qty = newQuantity
    }
    setQuantity(newQuantity)
  }

  const isActive = isButtonActive(mainValues?.isMultiSelect, optionValue)
  return (
    <Grid item key={optionValue?.bvid}>
      <Grid item>
        <Button
          data-id={optionValue?.title}
          key={optionValue?.bvid}
          title={optionValue?.title}
          variant="outlined"
          color={isActive ? "primary" : "secondary"}
          sx={{
            ...classes.button,
            ...optionButtonClass
          }}
          disabled={disabled}
          onClick={() => handleOptionButton(optionValue)}
        >
          <Grid container>
            <Grid item xs={12}>
              {optionValue ? limitText(optionValue?.title, 30, "...") : ""}
            </Grid>
            <Grid item xs={12}>
              {optionValue?.[priceKey] ? (
                <Typography
                  component="span"
                  color={
                    Number(optionValue?.[discountedPriceKey]) <
                    Number(optionValue?.[priceKey])
                      ? "error"
                      : ""
                  }
                >
                  {` + ${price}`}
                </Typography>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </Button>
      </Grid>
      <QuantityBar
        mainValues={mainValues}
        quantity={quantity}
        handleQuantity={handleQuantity}
      />
    </Grid>
  )
}

export default React.memo(SingleButton)
